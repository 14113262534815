import { graphql } from "gatsby";
import * as React from "react";
import Helmet from "react-helmet";
import { ImageTag } from "src/helper/image-helper";
import Footer from "src/components/footer";
import Header, { Props as HeaderProps } from "src/components/headers/header";
import { EventTemplateProps } from "src/templates/event-template";
import { ProductTemplate, ProductTemplateProps } from "src/templates/product-template";
import styled from "styled-components";
import { generateMedia } from "styled-media-query";
import { ModalProvider } from "styled-react-modal";
import Media from "react-media";
import Slick from "react-slick";
import { ProductSpec, ProductBonus, BaseColorProps } from "src/interfaces";
import { scrollTo } from "src/helper/link-helper";
import { ColorHelper } from "src/helper/color-helper";
import "modern-normalize";
import "../styles/normalize";

const SpecialModalBackground = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 30;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.5);
`;

const mediaMobile = generateMedia({
  tablet: "1000px"
}).lessThan("tablet");

const mediaDesktop = generateMedia({
  tablet: "1000px"
}).greaterThan("tablet");

export interface TopPageTemplateProps {
  id: string;
  title: string;
  logo?: string;
  basecolor?: string;
  event: EventTemplateProps;
  news: Array<{ text: string }>;

  past_event: Array<{
    url: string;
    shorthand: string;
  }>;

  carousel_images: Array<{
    pc_image: string;
    sp_image: string;
    link: string;
  }>;

  infomation_categories: Array<{
    infomation_category: string;
    infomations: Array<{
      title: string;
      image: string;
      author_name?: string;
      description: string;
    }>;
  }>;

  product_categories: Array<{
    product_category: string;
    basecolor?: string;
    products: ProductTemplateProps[];
  }>;
  is_preview?: boolean;
}

export class TopPageTemplate extends React.Component<TopPageTemplateProps, { is_preview: boolean }> {
  constructor(props: TopPageTemplateProps) {
    super(props);
  }

  private topCarouselRender(carousels: Array<{ pc_image: string; sp_image: string; link: string }>, isPreview: boolean, baseColor: string) {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 3000,
      appendDots: (dots: React.ReactNode) => <DotsWrapper basecolor={baseColor}>{dots}</DotsWrapper>
    };

    return (
      <CarouselContainer>
        <Slick {...settings}>
          {carousels &&
            carousels.map((value, index) => (
              <a
                href={value.link}
                onClick={(e: any) => {
                  scrollTo(value.link);
                  e.preventDefault();
                }}
                key={index}
              >
                <SpOnly>
                  <CarouselImage style={{ width: "100%", objectFit: "contain" }} src={value.sp_image} isPreview={isPreview}></CarouselImage>
                </SpOnly>
                <PcOnly>
                  <CarouselImage style={{ width: "100%", objectFit: "contain" }} src={value.pc_image} isPreview={isPreview}></CarouselImage>
                </PcOnly>
              </a>
            ))}
        </Slick>
      </CarouselContainer>
    );
  }

  public render() {
    const value = this.props;
    const basecolor = this.props.basecolor && /^#[0-9|a-f|A-F]{6}$/.test(this.props.basecolor) ? this.props.basecolor : "#50aafc";

    return (
      <ModalProvider backgroundComponent={SpecialModalBackground}>
        {value.news ? (
          <div>
            <InfomationContainer>
              {value.news.map((news, index) => (
                <div key={index}>
                  <InfomationCell>
                    <InfomationLabel>お知らせ</InfomationLabel>
                    <InfomationTextContainer>
                      <InfomationText>{news.text}</InfomationText>
                    </InfomationTextContainer>
                  </InfomationCell>
                  {index === value.news.length - 1 ? <div style={{ marginBottom: "8px" }}></div> : <InfomationHR></InfomationHR>}
                </div>
              ))}
            </InfomationContainer>
          </div>
        ) : null}
        <TopContainer>
          {this.topCarouselRender(value.carousel_images, value.is_preview ? true : false, basecolor)}
          {value.event.datetime1 && value.event.datetime1.enabled && (
            <div>
              <SectionHeaderText basecolor={basecolor} id="shutten" style={{ marginTop: "80px", marginBottom: "0px" }}>
                {value.event.datetime1.label}
              </SectionHeaderText>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center"
                }}
              >
                <EventInfoContainer>
                  <EventDateText>{value.event.datetime1.date}</EventDateText>

                  <EventPlaceText>
                    <div>{value.event.datetime1.place1}</div>
                    <div>{value.event.datetime1.place2}</div>
                  </EventPlaceText>

                  <EventBoothText>{value.event.datetime1.booth}</EventBoothText>
                </EventInfoContainer>
              </div>
            </div>
          )}
          {value.event.datetime2 && value.event.datetime2.enabled && (
            <div>
              <SectionHeaderText basecolor={basecolor} id="shutten" style={{ marginTop: "80px" }}>
                {value.event.datetime2.label}
              </SectionHeaderText>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center"
                }}
              >
                <EventInfoContainer>
                  <EventDateText dangerouslySetInnerHTML={{ __html: value.event.datetime2.dateplace }}></EventDateText>
                </EventInfoContainer>
              </div>
            </div>
          )}
          {value.infomation_categories.map((value, index) => {
            return (
              <div key={index}>
                <SectionHeaderText basecolor={basecolor} id={`category${index}`}>
                  {value.infomation_category}
                </SectionHeaderText>
                {value.infomations.map((value, index) => {
                  return (
                    <div key={index}>
                      <FreeInfoContainer basecolor={ColorHelper.convertToRGBAString(basecolor, "0.2")}>
                        <div style={{ width: "100%", maxWidth: "350px" }}>
                          <FreeInfoImg style={{ width: "100%" }} src={value.image} isPreview={this.props.is_preview} />
                        </div>
                        <FreeInfoTextContainer>
                          <FreeInfoTitle>{value.title}</FreeInfoTitle>
                          <FreeInfoAuthor>{value.author_name ? value.author_name : ""}</FreeInfoAuthor>
                          <FreeInfoDescription>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: value.description
                              }}
                            ></div>
                          </FreeInfoDescription>
                        </FreeInfoTextContainer>
                      </FreeInfoContainer>
                    </div>
                  );
                })}
              </div>
            );
          })}

          <ProductInfoContainer>
            {value.product_categories.map((category, index) => {
              const categoryBaseColor = category.basecolor ? category.basecolor : basecolor;
              return (
                <div key={index}>
                  <SectionHeaderText basecolor={categoryBaseColor} id={`productCategory${index}`}>
                    {category.product_category}
                  </SectionHeaderText>
                  {category.products.map((product, index) => {
                    return (
                      <div id={`product${index + 1}`} key={index} style={{ width: "100%" }}>
                        <ProductTemplate
                          {...product}
                          url={value.event.url}
                          basecolor={basecolor}
                          categoryBaseColor={categoryBaseColor}
                        ></ProductTemplate>
                        {index < category.products.length - 1 ? <ProductHR></ProductHR> : null}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </ProductInfoContainer>
        </TopContainer>
      </ModalProvider>
    );
  }
}

interface TopPageProps {
  data: {
    markdownRemark: {
      fields: {
        past_events?: Array<{
          shorthand: string;
          url: string;
        }>;
        product_categories?: Array<{
          product_category: string;
          basecolor?: string;
          products: Array<{
            id: string;
            frontmatter: {
              id: string;
              copyright: string;
              description: string;
              detail: string;
              gallery_images: Array<{
                product_image: string;
              }>;
              product_spec_first: ProductSpec;
              product_spec_second: ProductSpec;
              product_spec_third: ProductSpec;
              author_list: {
                is_author_list?: boolean;
              };

              name: string;
              price: string;
              product_bonus: ProductBonus;
              sub_detail: string;
              title: string;
              twitter_hashes: Array<{
                tag: string;
              }>;
              kind: string;
            };
          }>;
        }>;
      };
      frontmatter: {
        logo?: string;
        pageurl: string;
        og_image: string;
        og_description: string;
        basecolor?: string;
        detail_button: {
          detail: string;
          sub_detail: string;
        };
        news: Array<{ text: string }>;
        datetime1: {
          enabled: boolean;
          label: string;
          date: string;
          place1: string;
          place2: string;
          booth: string;
        };
        datetime2: {
          enabled: boolean;
          label: string;
          dateplace: string;
        };
        carousel_images: Array<{
          link: string;
          pc_image: string;
          sp_image: string;
        }>;
        infomation_categories_with_infomations: Array<{
          infomation_category: string;
          infomations: Array<{
            author_name?: string;
            description: string;
            image: string;
            title: string;
          }>;
        }>;
        name: string;
      };
    };
  };
}

const TopPage: React.SFC<TopPageProps> = ({ data }) => {
  const { markdownRemark: fields } = data;
  const news = fields.frontmatter.news;
  const carouselImages = fields.frontmatter.carousel_images;
  const infomationCategories = fields.frontmatter.infomation_categories_with_infomations;
  const tmpProductCategories = fields.fields && fields.fields.product_categories ? fields.fields.product_categories : [];
  const pastInfomations = fields.fields && fields.fields.past_events ? fields.fields.past_events : [];
  const event: EventTemplateProps = {
    name: fields.frontmatter.name,
    shorthand: "",
    url: fields.frontmatter.pageurl,
    label: "",
    datetime1: fields.frontmatter.datetime1,
    datetime2: fields.frontmatter.datetime2
  };
  const productCategories = tmpProductCategories.map(value => {
    return {
      product_category: value.product_category,

      // productの中身を、previewTemplateの形式に合うように変換
      products: value.products.map((value: any) => {
        const front = value.frontmatter;
        if (front.gallery_images) {
          const galleryImages = front.gallery_images.map((value: { product_image: any }) => value.product_image);
          front.gallery_images = galleryImages;
        }
        front.is_author_list = front.author_list ? front.author_list.is_author_list : false;
        front.detail = value.frontmatter.detail_button ? value.frontmatter.detail_button.detail : "";
        front.detail2 = value.frontmatter.detail_button ? value.frontmatter.detail_button.sub_detail : "";
        front.product_bonus = [front.product_bonus];
        front.productSpecs = [front.product_spec_first, front.product_spec_second, front.product_spec_third].filter(v => v);
        return front;
      })
    };
  });

  const headerProps: HeaderProps = {
    past_infomations: pastInfomations,
    categories: [
      ...(event.datetime1 && event.datetime1.enabled ? [{ name: event.datetime1.label, id: "shutten" }] : []),
      ...(event.datetime2 && event.datetime2.enabled ? [{ name: event.datetime2.label, id: "shutten2" }] : []),
      ...infomationCategories.map((value, index) => {
        return {
          name: value.infomation_category,
          id: "category" + index.toString()
        };
      }),
      ...productCategories.map((value, index) => {
        return {
          name: value.product_category,
          id: "productCategory" + index.toString()
        };
      })
    ],
    logo_image: fields.frontmatter.logo ? fields.frontmatter.logo : "/images/pixiv-white.svg",
    title: event.name,
    basecolor: fields.frontmatter.basecolor,
    pageurl: fields.frontmatter.pageurl
  };
  return (
    <Container>
      <Helmet
        title={data.markdownRemark.frontmatter.name}
        meta={[
          {
            name: "description",
            content: `${data.markdownRemark.frontmatter.og_description}`
          },
          {
            property: "og:title",
            content: data.markdownRemark.frontmatter.name
          },
          { property: "og:type", content: "website" },
          {
            property: "og:url",
            content: `https://goods.pixiv.net${data.markdownRemark.frontmatter.pageurl}`
          },
          {
            property: "og:image",
            content: `https://goods.pixiv.net${data.markdownRemark.frontmatter.og_image}`
          },
          { property: "og:description", content: `${data.markdownRemark.frontmatter.og_description}` },
          { name: "twitter:card", content: "summary_large_image" },
          { name: "twitter:site", content: "@pixiv" }
        ]}
        link={[{ rel: "icon", type: "image/vnd.microsoft.icon", href: "https://www.pixiv.net/favicon.ico" }]}
      >
        <title>{data.markdownRemark.frontmatter.name}</title>
        <script type="application/javascript">{`
      var _gaq = _gaq || [];
      _gaq.push(['_setAccount', 'UA-1830249-27']);
      _gaq.push(['_trackPageview']);

      (function() {
        var ga = document.createElement('script');
        ga.type = 'text/javascript';
        ga.async = true;
        ga.src =
          ('https:' == document.location.protocol
            ? 'https://ssl'
            : 'http://www') + '.google-analytics.com/ga.js';
        var s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(ga, s);
      })();
      `}</script>
      </Helmet>

      <Header {...headerProps}></Header>
      <TopPageTemplate
        id=""
        title=""
        event={event}
        basecolor={fields.frontmatter.basecolor}
        news={news}
        past_event={[]}
        carousel_images={carouselImages}
        infomation_categories={infomationCategories}
        product_categories={productCategories}
        is_preview={false}
      ></TopPageTemplate>
      <Footer></Footer>
    </Container>
  );
};

export const TopPageQuery = graphql`
  query MyQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      fields {
        past_events {
          shorthand
          url
        }
        product_categories {
          product_category
          basecolor
          products {
            id
            frontmatter {
              id
              copyright
              description
              detail_button {
                detail
                sub_detail
              }
              kind
              gallery_images {
                product_image
              }
              name
              price
              product_bonus {
                enabled
                product_bonus_description
                product_bonus_image
                product_bonus_title
              }
              title
              author_list {
                is_author_list
              }
              twitter_hashes {
                tag
              }
              product_spec_first {
                content
                enabled
                label
                url
              }
              product_spec_second {
                content
                enabled
                label
                url
              }
              product_spec_third {
                content
                enabled
                label
                url
              }
            }
          }
        }
      }
      frontmatter {
        name
        logo
        pageurl
        og_image
        og_description
        basecolor
        news {
          text
        }
        datetime1 {
          enabled
          label
          date
          place1
          place2
          booth
        }
        datetime2 {
          enabled
          label
          dateplace
        }
        carousel_images {
          link
          pc_image
          sp_image
        }
        infomation_categories_with_infomations {
          infomation_category
          infomations {
            author_name
            description
            image
            title
          }
        }
      }
    }
  }
`;

export default TopPage;

const PcOnly = styled.div`
  ${mediaMobile`
    display: none;
  `}

  ${mediaDesktop`
    display:block;
  `}
`;

const SpOnly = styled.div`
  ${mediaMobile`
    display: block;
  `}
  ${mediaDesktop`
    display:none;
  `}
`;

const Container = styled.div`
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  z-index: 0;
`;

const InfomationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 8px;
  ${mediaMobile`
    margin-top: 0px;
    margin-bottom: 0px;
  `}
`;

const InfomationHR = styled.hr`
  display: none;
  margin-top: 8px;
  margin-bottom: 0px;
  ${mediaMobile`
  display:block;
  width: 100%;
`}
`;

const InfomationCell = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 16px;
  margin-top: 4px;
  margin-right: 16px;
  ${mediaMobile`
  margin-right: 40px;
  margin-top: 8px;
`}
`;

const InfomationLabel = styled.div`
  background-color: red;
  font-size: 16px;
  line-height: 34px;
  width: 80px;
  height: 24px;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  font-weight: bold;

  color: white;

  ${mediaMobile`
    font-size: 10px;
    line-height: 34px;
    height: 16px;
    width: 64px;
    align-self: flex-start;
  `}
`;

const InfomationText = styled.div`
  font-size: 16px;
  line-height: 34px;
  margin-left: 8px;
  color: #000000;
  font-weight: 200;
  width: 240px;

  ${mediaMobile`
    font-size: 10px;
    line-height: 16px;
    width: 240px;
  `}
`;

const InfomationTextContainer = styled.div`
  display: flex;
  flex-direction: row;

  ${mediaMobile`
     flex-direction: column;
  `}
`;

const CarouselContainer = styled.div`
  width: 100%;
`;

const DotsWrapper = styled.div`
  /* ここでドットのスタイルをあてる */
  li button:before {
    color: #c4c4c4;
  }

  li.slick-active button:before {
    color: ${(props: BaseColorProps) => props.basecolor} !important;
  }
`;
const CarouselImage = styled(ImageTag)`
  object-fit: contain;
  width: 100%;
`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProductInfoContainer = styled.div`
  width: 100%;
`;

const SectionHeaderText = styled.h2`
  font-size: 16px;
  line-height: 24px;
  margin-top: 56px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
  color: #1f1f1f;
  ${mediaMobile`
    font-size: 12px;
    margin-top: 24px;
  `};

  &:after {
    content: "";
    width: 76px;
    height: 4px;
    background: ${(props: BaseColorProps) => props.basecolor};
    margin-top: 5px;
    margin-bottom: 24px;
    display: block;
    ${mediaMobile`
      width: 56px;
    `}
  }
`;

const EventInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 968px;
  align-items: center;

  ${mediaMobile`
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 360px;
  `}
`;

const EventDateText = styled.div`
  font-size: 48px;
  line-height: 72px;
  font-weight: bold;
  margin-right: 40px;
  ${mediaMobile`
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 8px;
    margin-right: 0px;
  `}
`;

const EventPlaceText = styled.div`
  font-size: 24px;
  line-height: 34px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  div {
    padding: 2px;
  }
  ${mediaDesktop`
    align-items: flex-start;
  `}
`;

const EventBoothText = styled.div`
  font-size: 96px;
  font-weight: bold;
  margin-bottom: 8px;
  ${mediaMobile`
    font-size: 48px;
    line-height: 34px;
    margin-bottom: 8px;
    margin-top: 8px;
  `}
`;

const ProductHR = styled.div`
  border: 0.5px dashed #a4a4a4;
`;

const FreeInfoTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: flex-start;
  margin-left: 24px;
  ${mediaMobile`
    flex-direction: column;
    align-items: center;
    align-self: center;
    margin-left: 0px;
  `}
`;

const FreeInfoContainer = styled.div`
  background: #ffffff;
  border: 8px solid ${(props: BaseColorProps) => props.basecolor};
  padding: 40px;
  margin: 0px 19px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  min-width: 1000px;
  width: 1000px;
  ${mediaMobile`
    flex-direction: column;
    padding:24px;
    margin: 16px;
    margin-bottom: 0px;
    width: initial;
    min-width: initial;
    max-width: initial;
  `}
`;

const FreeInfoImg = styled(ImageTag)`
  width: 100%;
  object-fit: contain;
  ${mediaMobile`
    width: 100%;
  `}
`;

const FreeInfoTitle = styled.div`
  font-size: 24px;
  line-height: 32px;
  font-weight: bold;
  text-align: left;
  ${mediaMobile`
    margin-top: 0px;
  `}
`;

const FreeInfoAuthor = styled.div`
  font-size: 12px;
  margin-top: 16px;
  line-height: 34px;
  font-weight: bold;
  ${mediaMobile`
    margin-top: 8px;
  `}
`;

const FreeInfoDescription = styled.div`
  font-size: 16px;
  line-height: 24px;
  margin-top: 32px;
  text-align: justify;
  width: 580px;
  ${mediaMobile`
    font-size: 12px
    margin-top: 16px;
    width: initial;
  `}
`;
