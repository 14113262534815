import React from "react";
import Slick from "react-slick";
import { ThreeCarousel } from "src/components/carouseles/three-carousel";
import { DetailModalButton } from "src/components/modal-buttons/detail-modal";
import ShareButton from "src/components/product/share-button";
import styled from "styled-components";
import { generateMedia } from "styled-media-query";
import { ImageTag } from "src/helper/image-helper";
import { ProductSpec, ProductBonus, BaseColorProps } from "src/interfaces";
import { ColorHelper } from "src/helper/color-helper";

const mediaMobile = generateMedia({
  tablet: "1000px"
}).lessThan("tablet");

const mediaDesktop = generateMedia({
  tablet: "1000px"
}).greaterThan("tablet");

export interface ProductTemplateProps {
  id: string;
  title: string;
  name: string;
  description: string;
  detail: string;
  detail2: string;
  copyright: string;
  kind: string;
  price: string;
  productSpecs: Array<ProductSpec>;
  is_author_list: boolean;
  gallery_images: string[];
  banner_image: string;
  product_bonus: Array<ProductBonus>;
  is_preview?: boolean;
  url?: string;
  basecolor?: string;
  categoryBaseColor?: string;
  twitter_hashes?: Array<{ tag: string }>;
}

export class ProductTemplate extends React.Component<ProductTemplateProps> {
  public render() {
    const value = this.props;
    const baseColor = this.props.basecolor ? this.props.basecolor : "#50aafc";
    const baseColor5 = ColorHelper.convertToRGBAString(this.props.categoryBaseColor ? this.props.categoryBaseColor : baseColor, "0.05");
    return (
      <ProductInfoContainer basecolor={baseColor5} id={`${value.id}`}>
        <UpperContainer style={{ paddingTop: "16px" }}>
          {value.is_preview ? (
            <ProductImagePreviewContainer>
              <MobileOnly>{this.renderProductCarousel(value.gallery_images)}</MobileOnly>
              <DesktopOnly>{this.renderProductThreeCarousel(value.gallery_images, baseColor)}</DesktopOnly>
            </ProductImagePreviewContainer>
          ) : (
            <ProductImageContainer>
              <MobileOnly>{this.renderProductCarousel(value.gallery_images)}</MobileOnly>
              <DesktopOnly>{this.renderProductThreeCarousel(value.gallery_images, baseColor)}</DesktopOnly>
            </ProductImageContainer>
          )}
          <ProductInfomationContainer>
            <ProductTextContainer>
              <ProductTextContainerUpper>
                <ProductInfoTypeText>{value.kind}</ProductInfoTypeText>
                <ShareButton
                  product={{
                    id: value.id,
                    kind: value.kind,
                    title: [value.name],
                    twitter_hashes: value.twitter_hashes
                      ? value.twitter_hashes.map(value => {
                          return value.tag;
                        })
                      : [""]
                  }}
                  basecolor={this.props.basecolor}
                  pageurl={value.url ? value.url : "/"}
                ></ShareButton>
              </ProductTextContainerUpper>
              <ProductInfoTitleText dangerouslySetInnerHTML={{ __html: value.name }}></ProductInfoTitleText>
              <ProductInfoPriceText dangerouslySetInnerHTML={{ __html: value.price }}></ProductInfoPriceText>
              <ProductInfoCopyrightText>{value.copyright}</ProductInfoCopyrightText>
            </ProductTextContainer>
            <ButtomContainer>
              <ProductInfoDescriptionText dangerouslySetInnerHTML={{ __html: value.description }}></ProductInfoDescriptionText>
              {value.productSpecs.map(spec => {
                return spec.enabled ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "4px",
                      marginBottom: "4px",
                      alignItems: "center"
                    }}
                  >
                    <ProductInfoLabelBox>{spec.label}</ProductInfoLabelBox>
                    {spec.url ? (
                      <ProductInfoLabelText>
                        <a href={spec.url} rel="noopener noreferrer" target="_blank">
                          {spec.content}
                        </a>
                      </ProductInfoLabelText>
                    ) : (
                      <ProductInfoLabelText dangerouslySetInnerHTML={{ __html: spec.content }}></ProductInfoLabelText>
                    )}
                  </div>
                ) : null;
              })}
              {value.is_author_list ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "4px",
                    marginBottom: "4px",
                    alignItems: "center"
                  }}
                >
                  <ProductInfoLabelBox>掲載作家一覧</ProductInfoLabelBox>
                  <ProductInfoLabelText>
                    <a href={`${value.url}/product/authors/${value.id ? value.id : value.name}`}>掲載作家一覧を見る</a>
                  </ProductInfoLabelText>
                </div>
              ) : null}
              <ProductInfomationContainerButton style={{ marginTop: "16px;" }}>
                <StyledDetailModalButton
                  buttonElement={
                    <DetailButton basecolor={this.props.basecolor ? this.props.basecolor : "#0096fa"}>詳細をみる</DetailButton>
                  }
                  title={value.name}
                  productSpecs={value.productSpecs}
                  id={value.id}
                  detail_description={[value.detail]}
                  sub_description={[value.detail2]}
                ></StyledDetailModalButton>
              </ProductInfomationContainerButton>
            </ButtomContainer>
          </ProductInfomationContainer>
        </UpperContainer>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginTop: "28px",
            width: "100%",
            maxWidth: "1080px"
          }}
        >
          <ProductBonusWrapper>
            {value.product_bonus &&
              value.product_bonus
                .filter(v => v)
                .map((bonus, index) =>
                  bonus.enabled
                    ? this.renderProductBonus(index, bonus.product_bonus_image, bonus.product_bonus_title, bonus.product_bonus_description)
                    : null
                )}
          </ProductBonusWrapper>
        </div>
      </ProductInfoContainer>
    );
  }

  private renderProductBonus(index: number, imageUrl: string, title: string, description: string) {
    if (title === "") {
      return null;
    }
    return (
      <ProductBonusArrowBox key={index}>
        <ProductBonusContentContainer>
          <ProductBonusImageContainer>
            <Image src={imageUrl} />
          </ProductBonusImageContainer>
          <ProductBonusTextContainer>
            <ProductBonusLabel>購入特典</ProductBonusLabel>
            <ProductBonusTitle>{title}</ProductBonusTitle>
            <ProductBonusDescription dangerouslySetInnerHTML={{ __html: description }}></ProductBonusDescription>
          </ProductBonusTextContainer>
        </ProductBonusContentContainer>
      </ProductBonusArrowBox>
    );
  }

  private renderProductThreeCarousel(images: string[], baseColor = "#50aafc") {
    return (
      <ThreeCarousel basecolor={baseColor}>
        {images.map((value, index) =>
          ImageTag({
            isPreview: this.props.is_preview ? true : false,
            key: index.toString(),
            style: { objectFit: "contain", width: "332px" },
            src: value
          })
        )}
      </ThreeCarousel>
    );
  }

  private renderProductCarousel(images: string[]) {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 3000,
      appendDots: (dots: any) => <DotsWrapper>{dots}</DotsWrapper>
    };

    return (
      <CarouselContainer>
        <Slick {...settings}>
          {images.map((value, index) => (
            <ImageTagStyled
              isPreview={this.props.is_preview ? true : false}
              key={index.toString()}
              src={value}
              style={{ width: "100%", objectFit: "contain", margin: "10px" }}
            ></ImageTagStyled>
          ))}
        </Slick>
      </CarouselContainer>
    );
  }
}

const MobileOnly = styled.div`
  display: none;
  ${mediaMobile`
      display:block;
  `}
`;

const Image = styled.img`
  height: 252px;
  object-fit: contain;
  margin: 0;
  padding: 0;
  ${mediaMobile`
    width:100%;
    height:initial;
  `}
`;

const DesktopOnly = styled.div`
  display: block;
  ${mediaMobile`
      display: none;
  `}
`;

const ImageTagStyled = styled(ImageTag)``;

const UpperContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1080px;
  justify-content: space-around;
  ${mediaMobile`
    flex-direction: column;
    padding: 0;
  `}
`;
const ButtomContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px;
  padding-bottom: 0px;
  width: 100%;
`;

const ProductInfomationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 472px;
  ${mediaMobile`
    height: initial;
  `}
`;

const ProductInfomationContainerButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 0px 0px;
  margin-top: 16px;
`;

const StyledDetailModalButton = styled(DetailModalButton)`
  align-self: flex-end;
  width: 100%;
`;

const CarouselContainer = styled.div`
  width: 100vw;
`;

const DetailButton = styled.div`
  border-radius: 22px;
  width: 100%;
  height: 32px;
  color: white;
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
  background: ${(props: BaseColorProps) => props.basecolor};
  text-decoration: none;
  &:hover {
    cursor: hand;
  }
`;

const DotsWrapper = styled.div`
  /* ここでドットのスタイルをあてる */
  li button:before {
    color: #c4c4c4;
  }

  li.slick-active button:before {
    color: #0096fa !important;
  }
`;
const ProductInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${(props: BaseColorProps) => props.basecolor};
  width: 100%;
  ${mediaDesktop`
    padding: 48px 96px;
  `}
`;

const ProductTextContainerUpper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  ${mediaMobile`
    margin-top: 32px;
  `}
`;

const ProductTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px;
  padding-top: 0px;
  width: 100%;
`;

const ProductImagePreviewContainer = styled.div`
  width: 100%;
  ${mediaDesktop`
    margin-left: 30px;
  `}
`;
const ProductImageContainer = styled.div`
  width: 100%;
  ${mediaDesktop`
    width: 455px;
    margin-right: 60px;
  `}
`;

const ProductInfoTypeText = styled.div`
  font-size: 12px;
  line-height: 34px;
  ${mediaDesktop`
  font-size: 16px;
  `}
`;

const ProductInfoTaxText = styled.span`
  font-size: 16px;
  font-weight: bold;
`;

const ProductInfoTitleText = styled.div`
  font-size: 24px;
  line-height: 32px;
  font-weight: bold;
  margin-top: 16px;
  ${mediaDesktop`
    font-size: 32px;
    margin-top: 8px;
  `}
`;
const ProductInfoPriceText = styled.div`
  font-size: 24px;
  line-height: 34px;
  margin-top: 8px;
  ${mediaDesktop`
    font-size: 32px;
    margin-top: 16px;
  `}
`;

const ProductInfoCopyrightText = styled.div`
  font-size: 10px;
  line-height: 22px;
  margin-top: 4px;
  ${mediaDesktop`
    font-size: 12px;
    margin-top:8px;
  `}
`;

const ProductInfoDescriptionText = styled.div`
  font-size: 12px;
  line-height: 20px;
  margin-top: 18px;
  margin-bottom: 10px;
  ${mediaDesktop`
    font-size: 16px;
  `}
`;

const ProductInfoLabelBox = styled.div`
  width: 126px;
  height: 24px;
  font-size: 10px;
  line-height: 34px;
  font-weight: bold;
  background-color: #6c6c6c;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  ${mediaDesktop`
    font-size: 12px;
    border-radius: 4px;
  `}
`;

const ProductInfoLabelText = styled.div`
  font-size: 12px;
  line-height: 18px;
  margin-left: 20px;
  width: calc(100% - 140px);
  ${mediaDesktop`
    font-size:16px;
  `};
`;

const ProductBonusContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  ${mediaMobile`
    flex-direction: column;
    flex-wrap: wrap;
    padding: 22px 27px 27px 27px;
    justify-content: center;
  `}
`;

const ProductBonusWrapper = styled.div`
  margin-top: 28px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 90%;
  margin-bottom: 20px;
  ${mediaDesktop`
    width: 100%;
    margin-bottom: 0px;
  `}
`;

const ProductBonusLabel = styled.div`
  width: 126px;
  height: 24px;
  font-size: 10px;
  line-height: 34px;
  font-weight: bold;
  background-color: #6c6c6c;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  color: white;
  display: none;
  ${mediaDesktop`
    font-size: 12px;
    display: flex;
  `}
`;

const ProductBonusTitle = styled.div`
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 14px;
  margin-top: 8px;
  line-height: 34px;
  ${mediaDesktop`
    font-size: 32px;
  `}
`;

const ProductBonusTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  flex-wrap: wrap;
  ${mediaMobile`
  align-items: center;
  justify-content: center;
  margin-left:5px;
  margin-right:5px;
  `}
`;

const ProductBonusImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${mediaDesktop`
  width: initial;
`}
`;
const ProductBonusDescription = styled.div`
  font-size: 16px;
  line-height: 20px;
  ${mediaMobile`
    font-size: 12px;
  `}
`;
const ProductBonusArrowBox = styled.div`
  position: relative;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  ${mediaDesktop`
    padding: 40px;
    width: 100%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  `}
  &::before,
  &::after {
    bottom: 100%;
    left: 227px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    ${mediaMobile`
      left: 50%;
    `}
  }

  &::after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #ffffff;
    border-width: 10px;
    margin-left: -10px;
  }
  &::before {
    border-color: rgba(229, 229, 229, 0);
    border-bottom-color: #e5e5e5;
    border-width: 11px;
    margin-left: -11px;
  }
`;
