import * as React from "react";
import { InternalSitePath } from "src/constant";
import { mobileBreakPointQuery } from "src/constant";
import { scrollTo } from "src/helper/link-helper";
import styled from "styled-components";

const Footer: React.SFC<{}> = () => {
  return (
    <Container>
      <img src={`${InternalSitePath.image_base}pixiv-logo.svg`} width="88px"></img>
      <a
        href="#"
        onClick={(e: any) => {
          scrollTo("#header");
          e.preventDefault();
        }}
      >
        <ToTopImg src={`${InternalSitePath.image_base}to-top.svg`} width="156px"></ToTopImg>
      </a>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 116px;
  padding: 0px 24px;
  ${mobileBreakPointQuery.for_styled_media.lessThan("tablet")`
    height:58px;
  `}
`;

const ToTopImg = styled.img`
  width: 156px;
  ${mobileBreakPointQuery.for_styled_media.lessThan("tablet")`
    width:98px;
  `}
`;

export default Footer;
