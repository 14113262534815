import { Link } from "gatsby";
import React from "react";
import { InternalSitePath } from "src/constant";
import styled from "styled-components";
import { generateMedia } from "styled-media-query";
import Modal from "styled-react-modal";
import { ProductSpec } from "src/interfaces";

const mediaMobile = generateMedia({
  tablet: "1000px"
}).lessThan("tablet");

const mediaDesktop = generateMedia({
  tablet: "1000px"
}).greaterThan("tablet");

const StyledModal = Modal.styled`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  width:295px;
  max-height:600px;
  z-index: 110;
  ${mediaDesktop`
    width:80%;
    min-width: 900px;
    max-width: 1000px;
    max-height:650px;
  `}
`;

interface State {
  isOpen: boolean;
}

interface Props {
  buttonElement: React.ReactElement;
  title: string;
  productSpecs: Array<ProductSpec>;
  id: string;
  detail_description: string[];
  sub_description: string[];
}

export class DetailModalButton extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      isOpen: false
    };

    this.toggleModal = this.toggleModal.bind(this);
  }

  public toggleModal(e: any) {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
    e.preventDefault();
  }

  public render() {
    const props = this.props;
    return (
      <div style={{ width: "100%" }}>
        <a href="#" onClick={this.toggleModal} style={{ textDecoration: "none" }}>
          {props.buttonElement}
        </a>
        <StyledModal isOpen={this.state.isOpen} onBackgroundClick={this.toggleModal}>
          <div
            style={{
              position: "absolute",
              right: "8px",
              top: "11px"
            }}
          >
            <a href="#" onClick={this.toggleModal}>
              <img src={`${InternalSitePath.image_base}error-octagon.svg`} width="24px" height="24px"></img>
            </a>
          </div>
          <ProductInfoContainer>
            <ProductTitle>{props.title}</ProductTitle>
            <ProductInfoUpperContainer>
              {props.productSpecs.map(value => {
                return value.enabled ? (
                  <ProductInfoSetContainer>
                    <ProductInfoLabelBoxDark>{value.label}</ProductInfoLabelBoxDark>
                    {value.url ? (
                      <ProductInfoLabelText>
                        <a href={value.url} target="_blank" rel="noopener noreferrer">
                          {value.content}
                        </a>
                      </ProductInfoLabelText>
                    ) : (
                      <ProductInfoLabelText>{value.content}</ProductInfoLabelText>
                    )}
                  </ProductInfoSetContainer>
                ) : null;
              })}
            </ProductInfoUpperContainer>
            <ProductHR></ProductHR>
            <ProductDetail>
              {props.detail_description.map((str, index) => (
                <div key={index}>
                  <div dangerouslySetInnerHTML={{ __html: str }}></div>
                </div>
              ))}
            </ProductDetail>
            <MobileOnly>
              <ProductHR></ProductHR>
            </MobileOnly>
            <ProductDetailWithBox>
              {props.sub_description.map((str, index) => (
                <div key={index}>
                  <div dangerouslySetInnerHTML={{ __html: str }}></div>
                </div>
              ))}
            </ProductDetailWithBox>
          </ProductInfoContainer>
        </StyledModal>
      </div>
    );
  }
}
/*
const ShareButton = styled.a`
  border-radius: 22px;
  width: 100%;
  height: 24px;
  color: white;
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
  background: #0096fa;
  text-decoration: none;
  &:hover {
    cursor: hand;
  }
`;
*/

const DesktopOnly = styled.div`
  display: block;
  width: 100%;
  ${mediaMobile`
      display: none;
  `}
`;

const MobileOnly = styled.div`
  display: none;
  ${mediaMobile`
      display:block;
      width: 100%;
  `}
`;

const ProductTitle = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  margin-top: 37px;
  ${mediaDesktop`
    display:none;
  `}
`;

const ProductHR = styled.div`
  border: 0.5px solid #6c6c6c;
  width: 100%;
`;

const ProductDetail = styled.div`
  color: black;
  margin-top: 24px;
  font-size: 10px;
  line-height: 16px;
  margin-bottom: 24px;
  text-align: justify;
  ${mediaDesktop`
    font-size: 16px;
     line-height: 28px;
  `}
`;

const ProductDetailWithBox = styled(ProductDetail)`
  font-size: 10px;
  line-height: 16px;
  margin-top: 24px;
  width: 100%;
  ${mediaDesktop`
    border: 1px solid #6c6c6c;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 20px;
    font-size: 16px;
  `}
`;

const ProductInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  padding: 0px 33px;
  ${mediaDesktop`
    align-items: flex-start;
  `}
`;

const ProductInfoUpperContainer = styled.div`
  align-items: space-around;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 33px;
  margin-bottom: 24px;
  width: 100%;
  &::after {
    content: "";
    width: 100%;
  }
  ${mediaDesktop`
    margin-top: 32px;
    margin-bottom: 32px;
    padding: 0px 56px;
    align-items: space-between;
  `}
`;
const ProductInfoSetContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
  &:nth-child(3) {
    order: 1;
  }
  ${mediaDesktop`
    margin-top: 24px;
  `}
`;

const ProductInfoLabelText = styled.p`
  margin-left: 16px;
  font-size: 10px;
  line-height: 15px;
  width: 140px;
  ${mediaDesktop`
    font-size: 16px;
    line-height: 24px;
    width: 180px;
  `}
`;

const ProductInfoLabelBoxDark = styled.div`
  width: 88px;
  height: 24px;
  font-size: 10px;
  line-height: 34px;
  font-weight: bold;
  background-color: #6c6c6c;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  ${mediaDesktop`
  `}
`;
