import * as React from "react";
import onClickOutside from "react-onclickoutside";
import { ShareTips } from "src/components/share-tips";
import styled from "styled-components";
import { BaseColorProps } from "src/interfaces";

interface State {
  listOpen: boolean;
}

interface Props {
  product?: {
    id: string;
    kind: string;
    title: string[];
    twitter_hashes: string[];
  };
  basecolor?: string;
  btnString?: string;
  pageurl: string;
}

class ShareButton extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      listOpen: false
    };
  }

  public toggleList(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    this.setState(prevState => ({
      listOpen: !prevState.listOpen
    }));
    e.preventDefault();
  }

  public handleClickOutside() {
    this.setState({
      listOpen: false
    });
  }

  public render() {
    const { listOpen } = this.state;

    return (
      <Wrapper>
        <MenuButton basecolor={this.props.basecolor ? this.props.basecolor : "#0096fc"} href="#" onClick={this.toggleList.bind(this)}>
          {this.props.btnString ? this.props.btnString : "この作品をシェア"}
        </MenuButton>
        {listOpen && (
          <div
            style={{
              position: "absolute",
              bottom: "calc(100% + 15px)",
              left: "-13px",
              zIndex: 100
            }}
          >
            <ShareTips
              basecolor={this.props.basecolor ? this.props.basecolor : "#0096fc"}
              product={this.props.product}
              direction="top"
              headText={this.props.btnString ? this.props.btnString : "この作品をシェア"}
              pageurl={this.props.pageurl}
            ></ShareTips>
          </div>
        )}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  position: relative;
  vertical-align: center;
`;

const MenuButton = styled.a`
  width: 120px;
  height: 24px;
  background-color: ${(props: BaseColorProps) => props.basecolor};
  border-radius: 4px;
  color: white;
  font-size: 12px;
  line-height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
`;

export default onClickOutside(ShareButton);
