import * as React from "react";
import styled from "styled-components";

interface State {
  position: number;
  isAnimation: boolean;
}

interface Props {
  children?: React.ReactNode;
  basecolor?: string;
}

export class ThreeCarousel extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      position: 1,
      isAnimation: true
    };
  }

  public render() {
    const children = React.Children.toArray(this.props.children);
    return (
      <Wrapper>
        <CarouselArrowRight onClick={() => this.nextSlide()}></CarouselArrowRight>
        <CarouselArrowLeft onClick={() => this.prevSlide()}></CarouselArrowLeft>
        <CarouselContainer position={this.state.position} isAnimation={this.state.isAnimation}>
          <CarouselSlot isAnimation={this.state.isAnimation} order={-2} currentKey={this.state.position - 1}>
            {children[children.length - 2]}
          </CarouselSlot>
          <CarouselSlot isAnimation={this.state.isAnimation} order={-1} currentKey={this.state.position - 1}>
            {children[children.length - 1]}
          </CarouselSlot>
          {React.Children.map(this.props.children, (child, key) => (
            <CarouselSlot isAnimation={this.state.isAnimation} key={key} order={key} currentKey={this.state.position - 1}>
              {child}
            </CarouselSlot>
          ))}
          <CarouselSlot isAnimation={this.state.isAnimation} order={children.length} currentKey={this.state.position - 1}>
            {children[0]}
          </CarouselSlot>
          <CarouselSlot isAnimation={this.state.isAnimation} order={children.length + 1} currentKey={this.state.position - 1}>
            {children[1]}
          </CarouselSlot>
        </CarouselContainer>
        <DotContainer>
          {React.Children.map(this.props.children, (_child, key) => {
            const numItems = React.Children.count(children) || 1;
            let position = this.state.position === 0 ? numItems : this.state.position % (numItems + 1);
            position = position === 0 ? 1 : position;
            return (
              <DotButton
                basecolor={this.props.basecolor ? this.props.basecolor : "#0096fa"}
                onClick={() => this.toPosition(key + 1)}
                active={position === key + 1}
              >
                •
              </DotButton>
            );
          })}
        </DotContainer>
      </Wrapper>
    );
  }

  private toPosition(position: number) {
    this.doSliding(position);
  }

  private async timeout(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  private async nextSlide() {
    const position = this.state.position + 1;
    const { children } = this.props;
    const numItems = React.Children.count(children) || 1;
    if (position === numItems + 2) {
      this.setState({
        position: 1,
        isAnimation: false
      });
      await this.timeout(10);
      return this.doSliding(2);
    }
    this.doSliding(position);
  }

  private async prevSlide() {
    const position = this.state.position - 1;
    const { children } = this.props;
    const numItems = React.Children.count(children) || 1;
    if (position === -1) {
      this.setState({
        position: numItems,
        isAnimation: false
      });
      await this.timeout(10);
      return this.doSliding(numItems - 1);
    }
    this.doSliding(position);
  }

  private doSliding(position: number) {
    this.setState({
      position,
      isAnimation: true
    });
  }
}

interface CarouselContainerProps {
  position: number;
  isAnimation: boolean;
}

const CarouselContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  transition: ${(props: CarouselContainerProps) => {
    return props.isAnimation ? "transform 0.5s ease" : "";
  }};

  transform: ${(props: CarouselContainerProps) => {
    const percent = 80 + props.position * 80;
    return "translateX( calc(-" + percent.toString() + "% + 62px))";
  }};
`;

const DotContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

interface DotProps {
  active: boolean;
  basecolor: string;
}

const DotButton = styled.div`
  border: 0;
  background: 0 0;
  cursor: pointer;
  font-size: 2em;
  line-height: 1.2em;
  margin: 0 8px;
  color: ${(props: DotProps) => (props.active ? props.basecolor : "#E5E5E5")};
`;

interface CarouselSlotProps {
  order: number;
  currentKey: number;
  isAnimation: boolean | null;
}

const CarouselSlot = styled.div`
  order: ${(props: CarouselSlotProps) => props.order};
  flex: 1 0 100%;
  flex-basis: 80%;
  margin: 0px;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 91%;
    height: 99.5%;
    background: #000000;
    opacity: ${(props: CarouselSlotProps) => (props.currentKey === props.order ? 0 : 0.4)};
    transition: ${(props: CarouselSlotProps) => {
      return props.isAnimation ? "opacity 0.5s ease" : "";
    }};
  }
`;

const Wrapper = styled.div`
  overflow: hidden;
  width: 455px;
  position: relative;
`;

const CarouselArrowRight = styled.div`
  &::before {
    position: absolute;
    top: 0;
    left: calc(87%);
    width: 30px;
    height: 100%;
    content: "";
    background: #ebf5fb;
    z-index: 9;
    display: none;
  }
  &::after {
    position: absolute;
    top: calc((100% - 48px) / 2);
    left: calc(100% - 59px);
    margin: auto;
    content: "";
    vertical-align: middle;
    width: 9px;
    height: 9px;
    border-top: 4px solid #858585;
    border-right: 4px solid #858585;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    border-top: 4px solid #a4a4a4;
    border-right: 4px solid #a4a4a4;
    left: calc(100% - 7% - 20px);
    z-index: 10;
  }
`;

const CarouselArrowLeft = styled.div`
  &::before {
    position: absolute;
    top: 0;
    left: calc(7%);
    width: 30px;
    height: 100%;
    content: "";
    background: #ebf5fb;
    z-index: 9;
    display: none;
  }
  &::after {
    position: absolute;
    top: calc((100% - 48px) / 2);
    margin: auto;
    content: "";
    vertical-align: middle;
    left: calc(42px);
    width: 9px;
    height: 9px;
    border-top: 4px solid #858585;
    border-right: 4px solid #858585;
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
    z-index: 10;
    border-top: 4px solid #a4a4a4;
    border-right: 4px solid #a4a4a4;
  }
`;
